.dropdown-displayed-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  user-select: none;
}
.dropdown-displayed-item .logdb-status {
  padding-right: 5px;
  width: 110px !important;
}
.dropdown-displayed-item .logdb-noactive-status {
  padding-right: 5px;
  width: 150px !important;
}
.dropdown-displayed-item .config-btn {
  width: 26px !important;
  height: 22px;
  background-image: url('../images/change-settings.svg');
  background-repeat: no-repeat;
  background-size: 22px 22px;
  margin-right: 2px;
}
.dropdown-displayed-item .calender-arrow {
  font-size: 9px;
  vertical-align: top;
}
.loading-container :global div {
  width: 32px !important;
  height: 32px !important;
}
