.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.header {
  padding: 12px 14px;
  margin-bottom: 16px;
}
.title {
  margin-bottom: 10px;
}
.total {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--TreeMapChart-total-text-color, #4a90e2);
  padding-left: 8px;
}
.unitLabel {
  width: 196px;
  text-align: right;
  margin-bottom: -3px;
  font-size: 12px;
  font-weight: 300;
  color: var(--TreeMapChart-unit-label-text-color, #b5b5b5);
  padding: 0 6px;
  height: 20px !important;
}
.treeMap {
  background: #ffffff;
  border-radius: 4px;
  height: calc(100% - 16px);
  width: 100%;
  padding: 0 5px;
}
.treeMap :global .ant-tabs {
  overflow: visible;
  height: 100%;
}
.treeMap :global .ant-tabs-vertical > .ant-tabs-bar .ant-tabs-tab {
  margin-bottom: 0;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 4px 0 0 4px;
  height: auto;
}
.treeMap :global .ant-tabs-vertical > .ant-tabs-bar .ant-tabs-tab:hover {
  background: var(--TreeMapChart-lineColor-ant-tab-bg-color, #e7f7ff);
  padding-right: 0px;
  border-radius: 4px;
}
.treeMap :global .ant-tabs-vertical > .ant-tabs-bar .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--TreeMapChart-lineColor-ant-tab-bg-color, #e7f7ff);
  border: 1px solid var(--TreeMapChart-lineColor-ant-tab-active-border-color, #4a90e2);
  margin-right: 0;
}
.treeMap :global .ant-tabs-vertical.ant-tabs-left > .ant-tabs-content {
  margin-top: -30px !important;
  background: var(--TreeMapChart-lineColor-ant-tab-bg-color, #e7f7ff);
  border-radius: 4px;
  padding: 0 16px;
  height: 100%;
}
.treeMap :global .ant-tabs-vertical.ant-tabs-left > .ant-tabs-content .ant-tabs-tabpane-active {
  height: 100%;
}
.treeMap :global .ant-tabs-vertical.ant-tabs-left > .ant-tabs-bar {
  border-right: none;
}
.treeMap :global .ant-tabs-vertical .ant-tabs-ink-bar {
  display: none !important;
}
.treeMap :global .highcharts-treemap-series > .highcharts-label > span {
  padding: 0px 10px;
}
.tab {
  width: 180px;
  text-align: left;
}
.tabIcon {
  width: 16px;
  height: 16px;
  border-radius: 100px;
  display: inline-block;
  background-color: var(--TreeMapChart-tabIcon-bg-color, #2c2c2c);
  font-size: 9px;
  font-weight: normal;
  text-align: center;
  line-height: 16px;
  color: var(--TreeMapChart-tabIcon-text-color, #fff);
  vertical-align: middle;
  margin-right: 8px;
  position: relative;
  left: 0px;
  bottom: 4px;
}
.tabLabel,
.tabValue {
  font-size: var(--font-size-base, 14px);
  font-weight: 300;
}
.tabLabel {
  position: relative;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 70px;
}
.tabValue {
  color: var(--TreeMapChart-tabValue-text-color, #616161);
  float: right;
}
.panel {
  background: var(--TreeMapChart-panel-bg-color, #e7f7ff);
  padding: 16px 0;
  height: 100%;
}
.panel :global div[data-highcharts-chart] {
  height: 100%;
}
.panel :global div[data-highcharts-chart] > div {
  border-radius: 4px;
  border: 1px solid var(--TreeMapChart-panel-div-border-color, #e3e3e3);
}
.panelTitle {
  font-size: 14px;
  font-weight: normal;
  color: var(--TreeMapChart-panelTitle-text-color, #363636);
  margin-bottom: 8px;
}
.singleton .title {
  margin-bottom: 0;
}
.singleton .content {
  padding: 0 15px 10px 15px;
  height: 100%;
}
.singleton .content :global .ant-tabs-tabpane-active div[data-highcharts-chart] {
  height: 100%;
}
.singleton .content :global .ant-tabs-tabpane-active div[data-highcharts-chart] > div {
  border-radius: 4px;
  border: 1px solid var(--TreeMapChart-panel-div-border-color, #e3e3e3);
}
.multi :global .ant-tabs-tabpane-active div[data-highcharts-chart] {
  height: calc(100% - 40px);
}
.multi :global .ant-tabs-tabpane-active div[data-highcharts-chart] > div {
  border-radius: 4px;
  border: 1px solid var(--TreeMapChart-panel-div-border-color, #e3e3e3);
}
