.container {
  position: relative;
  height: 150px;
  width: 800px;
}
.title {
  text-align: center;
  font-size: 14px;
  color: #2c2c2c;
  padding: 4px;
  width: 800px;
}
.wrapper {
  border-radius: 8px;
  border: solid 1px #d2d2d2;
  background-color: #ffffff;
  height: 110px;
  display: inline-block;
  font-size: 12px;
  color: #363636;
  text-align: center;
  position: absolute;
}
.wrapper :global ul {
  display: inline-grid;
  list-style: none;
  line-height: 20px;
  padding-inline-start: 0;
}
.long-word {
  overflow: hidden;
  /* 超出长度的部分隐藏 */
  text-overflow: ellipsis;
  /* 超过长度，末尾已 ... 补全  */
  white-space: nowrap;
}
.dash-icon :global svg {
  stroke: #7ed321;
}
.left {
  width: 120px;
  left: 0;
  padding: 10px;
}
.middle {
  width: 300px;
  left: 250px;
  padding: 20px 0;
}
.middle :global .content {
  padding: 0 5px;
  display: inline-block;
}
.right {
  width: 120px;
  left: 680px;
  padding: 20px;
}
.dash {
  position: absolute;
  width: 130px;
  border: 1px dashed #7ed321;
}
.left-line {
  left: 120px;
  top: 59px;
}
.right-line {
  top: 59px;
  left: 550px;
}
.stream1 {
  position: absolute;
  color: #2c2c2c;
  font-size: 12px;
  width: 60px;
  text-align: center;
  top: 40px;
  left: 155px;
  height: 20px;
  line-height: 20px;
}
.stream2 {
  position: absolute;
  color: #2c2c2c;
  font-size: 12px;
  width: 60px;
  text-align: center;
  top: 64px;
  left: 155px;
  height: 20px;
  line-height: 20px;
}
.stream3 {
  position: absolute;
  color: #2c2c2c;
  font-size: 12px;
  width: 60px;
  text-align: center;
  top: 44px;
  left: 590px;
  height: 20px;
  line-height: 20px;
}
.stream4 {
  position: absolute;
  color: #2c2c2c;
  font-size: 12px;
  width: 60px;
  text-align: center;
  top: 64px;
  left: 590px;
  height: 20px;
  line-height: 20px;
}
.error {
  border: 1px dashed red;
}
.error-icon {
  color: red !important;
}
.close-icon-right {
  color: red;
  position: absolute;
  top: 53px;
  left: 612px;
}
.edit-icon {
  position: absolute;
  top: 21px;
  left: 120px;
  border: 1.5px solid #2c2c2c;
  border-radius: 100px;
  padding: 8px;
  line-height: 1;
}
.edit-vip {
  position: absolute;
  width: 298px;
  text-align: center;
  top: 70px;
}
.edit-text {
  position: absolute;
  top: 8px;
  color: #4a90e2;
  left: 253px;
  font-weight: 300;
}
.waf-middle {
  text-align: left;
}
.waf-content {
  width: 150px;
  text-align: center;
}
.gifw-content {
  padding: 0;
}
.middle-content {
  border: 1px solid #ccc;
  margin: 8px;
  border-radius: 4px;
  line-height: 20px;
}
.middle-icon {
  position: absolute;
  top: 50px;
}
