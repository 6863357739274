.flow {
  position: relative;
  font-size: 14px;
}
.flow-bwrapper {
  border-radius: 8px;
  background-color: #ffffff;
  border: solid 1px #d2d2d2;
  display: inline-block;
  padding: 8px;
  text-align: center;
}
.flow-lwrapper {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border: solid 1px #d2d2d2;
  padding: 8px;
  display: inline-block;
}
.flow-left {
  position: absolute;
  left: 0;
}
.flow-center {
  position: absolute;
  left: 290px;
  text-align: center;
}
.flow-right {
  position: absolute;
  left: 700px;
}
.flow-line {
  position: absolute;
  top: 75px;
  width: 119px;
  left: 170px;
  border: 1px #7ed321 dashed;
}
.flow-abspan {
  position: absolute;
  padding: 2px 4px;
  top: 64px;
  left: 600px;
  background-color: #ffffff;
  color: #fff;
}
.flow-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.flow-icon {
  position: absolute;
  top: 65px;
}
.flow-action-icon {
  width: 56px;
  display: inline-block;
  text-align: center;
}
.flow-audit {
  padding-top: 8px;
}
.flow-event-inner {
  padding: 0 8px;
}
.flow-event-up-lebal {
  position: absolute;
  top: 44px;
  left: 300px;
}
.flow-event-up-lebal span {
  font-weight: 500;
}
.flow-event-down-lebal {
  position: absolute;
  top: 68px;
  left: 300px;
}
.flow-event-down-lebal span {
  font-weight: 500;
}
.ellipsis-text-overflow,
.pull-up-text-overflow {
  overflow: hidden;
  /* 超出长度的部分隐藏 */
  text-overflow: ellipsis;
  /* 超过长度，末尾已 ... 补全  */
  white-space: nowrap;
  width: 270px;
}
.ellipsis-text-overflow-short {
  overflow: hidden;
  /* 超出长度的部分隐藏 */
  text-overflow: ellipsis;
  /* 超过长度，末尾已 ... 补全  */
  white-space: nowrap;
  width: 150px;
}
