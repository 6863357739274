.a10-configlist--fields {
  display: block !important;
}
.a10-configlist-layout-fields-row {
  display: flex;
  align-items: center;
}
.a10-configlist-layout-fields-row .a10-configlist__remove {
  transform: none;
}
.a10-configlist-layout-fields-content {
  background-color: #f7faff;
  padding: 24px;
  flex: 1;
}
.a10-configlist-layout-fields--newRowBasedOn {
  background-color: transparent;
  padding: 0;
}
.a10-configlist-layout-fields--newRowBasedOn .a10-configlist-layout-fields-block:not(.newRowBasedOn) {
  background-color: #f7faff;
  padding: 24px;
  margin-bottom: 30px;
}
.a10-configlist-layout-fields--newRowBasedOn .a10-configlist-layout-fields-block:not(.newRowBasedOn):last-child {
  margin-bottom: 0;
}
.a10-configlist-layout-fields-block {
  width: 100%;
}
.a10-configlist-layout-fields-block .a10-configlist-layout-fields-field {
  float: left;
}
.a10-configlist-layout-fields-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
  margin-bottom: 18px;
}
.a10-configlist-layout-fields-removeBtn {
  float: right;
  position: absolute;
  right: -2.5%;
  bottom: 25% !important;
}
.a10-configlist-widget .sform-widget {
  min-width: 160px;
}
.a10-configlist--grid {
  display: block !important;
}
.a10-configlist--grid-container {
  display: flex;
  align-items: center;
}
.a10-configlist--grid-container .remove-btn {
  line-height: normal;
}
.a10-configlist--grid-container .remove-btn .a10-configlist__remove {
  margin-left: 16px;
}
.a10-configlist--grid-field-group {
  display: grid;
  gap: 10px 24px;
  padding: 16px;
  background-color: #f7faff;
  flex: 1;
}
.a10-configlist--grid-field {
  display: grid;
  grid-template-columns: 140px auto;
  column-gap: 16px;
  align-items: start;
}
