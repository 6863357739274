:global body {
  overflow: auto;
}
.log-panel :global .ant-table-tbody > tr > td {
  color: var(--logpanel-log-panel-td-text-color, #363636);
}
.log-panel :global .ant-tree li .ant-tree-node-content-wrapper {
  width: 100% !important;
}
.log-panel :global .a10-filtertree-filterComponent {
  overflow: hidden;
}
.log-panel :global .a10-filtertree-filterComponent .ant-select-small .ant-select-dropdown-menu-item {
  font-size: var(--font-size-base, 14px);
  line-height: 30px;
  padding-left: 4px !important;
}
.log-panel :global .ant-radio-button-wrapper-checked .logpanel-header-sublabel-num {
  background-color: #363636;
}
.log-panel :global .ant-table {
  font-size: 12px;
}
.log-panel :global .ant-table .sessionStatus {
  padding: 0px 0px 3px 0px;
}
.log-panel :global .ant-table .sessionStatus .a10-status-text {
  margin: 0px;
}
.log-panel :global .ant-collapse-header {
  font-size: 12px;
  padding-left: 30px !important;
}
.log-panel :global .ant-collapse-header .ant-collapse-arrow {
  left: 0px !important;
}
.log-panel :global .ant-collapse-content-box {
  font-size: 12px;
}
.log-panel-header {
  position: fixed;
  z-index: 100;
  width: 100%;
  margin-top: -90px;
}
.log-panel-context {
  margin-top: 90px;
}
.log-panel-context :global .a10-chart-no-data-wrapper {
  background-color: white;
}
.show-search {
  width: 500px;
  float: right;
}
.show-title {
  float: left;
  line-height: 32px;
}
.show-title-text {
  font-weight: 500;
}
.badge {
  background: #999999;
  border-radius: 10px;
  padding: 2px 8px;
  margin-left: 8px;
  color: #fff;
  font-size: 12px;
}
.drag-bar {
  width: 30px;
  height: 10px;
  background-color: var(--logpanel-drag-bar-bg-color, #000);
  cursor: glab;
}
.drawer {
  color: var(--logpanel-drawer-text-color, #d2d2d2);
  z-index: 100;
}
.drawer :global .ant-drawer-content {
  overflow: auto;
  background-color: var(--logpanel-bg-color, #efefef);
}
.drawer :global .ant-drawer-body {
  padding: 0;
}
.trace-chart {
  padding: 2px !important;
  height: 270px !important;
  background-color: white;
  background-clip: content-box;
}
.trace-chart-config svg {
  width: 15px !important;
}
.trace-chart-chartType {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
}
.trace-chart :global .a10-chart {
  height: 235px !important;
  border: none;
  margin: 1px;
}
.trace-chart :global .a10-chart-content {
  top: 0px;
}
.trace-chart :global .a10-chart-content-filter {
  top: 210px !important;
  margin-top: 0px !important;
}
.rateLabel {
  margin-left: 12px;
  color: var(--logpanel-srate-color, #ffffff);
  font-weight: 400;
  font-size: 12px;
  height: 15px;
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  background: var(--logpanel-srate-bg-color, #777777);
  padding: 4px 8px;
  top: 0px;
  position: relative;
  text-decoration-color: var(--logpanel-srate-bg-color, #777777);
  text-decoration-line: underline;
}
