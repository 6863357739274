.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.lists {
  overflow-y: auto;
}
.listTitle {
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--ListDoubleChart-normalFont-text-color, #363636);
  font-family: var(--font-family, Roboto);
  font-size: 14px;
  margin: 7px 0px 30px 10px;
  float: left;
  display: flex;
}
.listHeader {
  width: 100%;
  height: 16px;
}
.listVersion {
  float: right;
  margin: 7px 10px 30px 10px;
  display: flex;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--ListDoubleChart-normalFont-text-color, #363636);
  font-family: var(--font-family, Roboto);
  font-size: 14px;
}
.listRow {
  border-radius: 4px;
  border: solid 1px var(--ListDoubleChart-listRow-border-color, #f1f8ff);
  background-color: var(--ListDoubleChart-listRow-bg-color, #fafdff);
  margin: 10px;
}
.progressBarStyle {
  border-radius: 32px;
  border: solid 1px var(--ListDoubleChart-progressBar-border-color, #e7ecf1);
  background-color: var(--ListDoubleChart-progressBar-bg-color, #fcfcfc);
  width: 100%;
  height: 10px;
}
.innerProgressBar {
  border-radius: 32px;
  border: solid 1px var(--ListDoubleChart-innerProgressBar-border-color, #e7ecf1);
  height: 8px;
  border-radius: 4px;
  background-color: var(--ListDoubleChart-innerProgressBar-bg-color, #00aa6c);
}
.totalTitle {
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--ListDoubleChart-normalFont-text-color, #363636);
  font-family: var(--font-family, Roboto);
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.totalCount {
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--ListDoubleChart-normalFont-text-color, #363636);
  font-family: var(--font-family, Roboto);
  font-size: 12px;
  color: var(--ListDoubleChart-totalCount-text-color, #00aa6c);
}
.marginBottom {
  margin-bottom: 7px;
}
.progressRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
}
.progressBarGroups {
  border-right: 1px dashed var(--ListDoubleChart-progressBarGroups-border-color, #e3e3e3);
  margin-right: 20px;
}
.progressRowTitle {
  width: 20%;
  margin: 0px 5px 0px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--ListDoubleChart-normalFont-text-color, #363636);
  font-family: var(--font-family, Roboto);
  font-size: 12px;
}
.progressRowBar {
  width: 50%;
  display: flex;
  align-items: center;
  margin-right: 32px;
}
.counterLabel {
  background-color: var(--ListDoubleChart-counterLabel-bg-color, #81C784);
  border-radius: 66%;
  width: 16px;
  height: 16px;
  padding: 0px;
  /* padding-bottom: 3px; */
  line-height: 16px;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--ListDoubleChart-counterLabel-text-color, #ffffff);
  margin-right: 7px;
}
