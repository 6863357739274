.search-bar {
  height: 25px;
}
.search-bar :global input.ant-input {
  max-height: 25px;
  height: 25px;
  border: 1px solid #afd7ff;
}
.search-bar :global svg.ant-input-search-icon {
  font-size: 20px;
}
