.a10-edit-table > .a10-edit-table__addnew {
  position: absolute;
  top: -30px;
  right: 0;
  margin: 0;
  display: flex;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}
.a10-edit-table > .a10-edit-table__addnew.disabled {
  opacity: 0.6;
}
.a10-edit-table > .a10-edit-table__addnew i {
  border: 0;
}
.a10-edit-table .delete-button .anticon {
  cursor: pointer;
}
.a10-edit-table .sform-edittable-delete-button {
  display: inline-block;
}
.a10-edit-table .disabled {
  pointer-events: none;
}
.a10-edit-table-cell-value-wrap {
  height: 21px;
  padding-right: 24px;
}
.a10-edit-table--rowValue .ant-form-item-control .ant-form-explain {
  margin-top: 20px;
}
