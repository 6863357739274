.content {
  color: #363636;
  line-height: 30px;
  font-weight: 500;
}
.value {
  font-weight: 300;
}
.info {
  line-height: 25px;
  padding-left: 16px;
}
.ellipsis-text-overflow {
  display: inline-block;
  min-width: 100px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ellipsis-text-overflow.long-string {
  min-width: 200px;
  max-width: 300px;
}
.table_row {
  border-bottom: 1px solid #ccc;
  height: 48px;
  line-height: 48px;
}
.content-row {
  margin-bottom: 12px;
}
.msg {
  padding: 16px 0;
}
.no-collapse :global .ant-collapse {
  border: 0px !important;
}
.no-collapse :global .ant-collapse-content {
  border: 0px !important;
}
.no-collapse :global .ant-collapse-item {
  border: 0px !important;
}
