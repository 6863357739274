.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.serverHealthContent {
  padding: 23px 14px;
}
.rows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.row {
  display: flex;
  flex-direction: row;
  flex: 1 0 100%;
  align-items: center;
  margin-bottom: 18px;
  height: 16px;
  padding: 14px 12px;
  border: solid 1px #ffffff;
}
.rowHover {
  transition: 0.3s;
}
.rowHover:hover {
  border-radius: 18px;
  border: solid 1px #eaeaea;
  background-color: #f3f3f3;
}
.value {
  width: 100%;
  height: 12px;
  display: flex;
  border-radius: 9px;
  background-color: var(--ServerHealth-value-text-color, #4dd0e1);
  position: relative;
  overflow: hidden;
}
.rowCaption {
  width: 123px;
  height: 19px;
  font-family: var(--font-family, Roboto);
  font-size: var(--font-size-lg, 16px);
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--ServerHealth-rowCaption-text-color, #6e6e6e);
  width: 250px;
  max-width: 225px;
  min-width: 225px;
  display: flex;
  align-items: center;
}
.rowTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 45px);
}
.oval {
  width: 8px;
  height: 16px;
  border-radius: 100%;
  margin-right: 4px;
}
.highlight {
  position: absolute;
  height: 100%;
}
.timeline {
  width: calc(100% - 225px);
  height: 19px;
  float: right;
  display: flex;
  justify-content: space-between;
}
.tickInterval {
  height: 19px;
  font-family: var(--font-family, Roboto);
  font-size: var(--font-size-lg, 16px);
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--ServerHealth-tickInterval-text-color, #2c2c2c);
}
