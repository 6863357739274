/* Only import this file if you want to access the variables, e.g., overriding color of a widget  */
/***** Colors *****/
/* Naming convention of color:
   @color-{1}-{2}-{3}

   1. Representative name of the group of colors. E.g., Coral, Marigold, and Lilac
   2. Specify light or dark. This is optional
   3. Number (X00) used to represent light and dark variant
      (similar to how font-weights are numbered. Ref: https://material.io/design/color/the-color-system.html#color-usage-palettes)
*/
/***** Typography *****/
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/***** A10 defined theme, temporarily put here. Will remove them *****/
/***** A10 defined theme *****/
div.detail-widget-slider {
  display: flex;
  align-items: center;
}
div.detail-widget-slider--caption {
  height: 8px;
  font-size: 10px;
  color: #363636;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
div.detail-widget-slider--railContainer {
  height: 3em;
  margin: 0 3px;
}
div.detail-widget-slider--railContainer .rail {
  border: 0px;
  position: relative;
  top: 1.5em;
  z-index: 1;
  box-shadow: 1px 1px 0 0 #d2d2d2;
  background-color: #eee;
  height: 4px;
  border-radius: 4px;
}
div.detail-widget-slider--railContainer .thumbWrapper {
  position: absolute;
  z-index: 4;
}
div.detail-widget-slider--railContainer .thumbWrapper .thumb {
  border-radius: 7.5px;
  position: absolute;
  top: -4.5px;
  z-index: 1;
  width: 13px;
  height: 13px;
  border: 1.5px solid #afd7ff;
  background-color: #fff;
}
div.detail-widget-slider--railContainer .thumbWrapper .thumb:focus {
  outline: none;
}
div.detail-widget-slider--railContainer .thumbWrapper .caption {
  height: 8px;
  font-size: 10px;
  color: #363636;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  height: 30px;
  position: relative;
  top: 11px;
  text-align: center;
  left: -50%;
}
div.detail-widget-slider--railContainer .thumbWrapper .caption.captionInput {
  top: 14px;
}
div.detail-widget-slider--railContainer .thumbWrapper .caption.captionPercent {
  transform: translate(7.5px);
}
div.detail-widget-slider--railContainer .thumbWrapper .inputBox {
  left: 7px;
  position: relative;
  border-radius: 2px;
  padding: 5px 8px;
  width: fit-content;
  margin: auto;
  border: 1px solid #afd7ff;
  background-color: #fff;
}
div.detail-widget-slider--railContainer .thumbWrapper .inputBox:after {
  position: absolute;
  width: 10px;
  height: 10px;
  top: -1px;
  left: 50%;
  margin-left: -5px;
  content: '';
  transform: rotate(45deg);
  margin-top: -5px;
  background-color: #fff;
  border: 1px solid #afd7ff;
  border-bottom: 0px;
  border-right: 0px;
  border-top-left-radius: 3px;
}
div.detail-widget-slider--railContainer .thumbWrapper .inputBox input.ant-input {
  border: none !important;
  width: 100%;
  padding: 0px !important;
  text-align: center;
  height: 100%;
  font-size: 10px;
  color: #363636;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
div.detail-widget-slider--railContainer .thumbWrapper .inputBox input.ant-input:focus {
  box-shadow: none;
}
div.detail-widget-slider--railContainer .thumbWrapper:hover {
  animation: up 0.2s ease-in forwards;
}
div.detail-widget-slider--railContainer .sliderBg-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 4px;
}
div.detail-widget-slider--railContainer .sliderBg-container .sliderBg {
  position: absolute;
  height: 4px;
}
div.detail-widget-slider--railContainer .sliderBg-container .goodBg {
  background-color: #81c784;
}
div.detail-widget-slider--railContainer .sliderBg-container .warningBg {
  background-color: #ffba47;
}
div.detail-widget-slider--railContainer .sliderBg-container .dangerBg {
  background-color: #e57373;
}
@keyframes up {
  to {
    z-index: 5;
    /* end */
  }
}
