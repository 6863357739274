.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.barCaption {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.barContent {
  padding: 0 14px;
  margin-top: 10px;
}
.bars {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  height: 20px;
}
.barTitle {
  margin-right: 26px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: var(--font-size-sm, 12px);
  color: var(--BarCaption-barTitle-text-color, #000000);
}
.barContainer {
  width: 100%;
  height: 20px;
}
.bar {
  position: relative;
  width: 20px;
  height: 100%;
  border-radius: 2px;
  margin-right: 4px;
}
.caption {
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  margin-left: 4px;
  font-size: 12px;
  font-weight: 300;
  color: var(--BarCaption-progressCaption-text-color, #ffffff);
  border-radius: 2px;
  background-color: var(--BarCaption-progressCaption-bg-color, #919191);
  padding: 0px 6px;
  white-space: nowrap;
  line-height: 20px;
  opacity: 1;
}
.tooltip {
  display: flex;
  width: 100%;
}
.topUsedIpsContainer {
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 21px;
}
.topUsedIpsColumn {
  width: 50%;
  height: 100%;
}
.ipTitle {
  margin-top: 13px;
  margin-bottom: 9px;
  font-weight: 500;
}
.caption {
  font-size: var(--font-size-base, 14px);
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--TopUsedIps-caption-text-color, #000000);
  position: absolute;
  bottom: 17px;
}
