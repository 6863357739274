.main-chart {
  background-color: white;
}
.main-chart .main-title {
  padding: 5px;
}
.main-chart .main-title-text {
  color: var(--multichart-total-text-color, #2c2c2c);
  font-size: 12px;
  display: inline-flex;
  margin-left: 5px;
  -webkit-text-decoration-style: dashed;
  text-decoration-style: dashed;
  -webkit-text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  text-underline-position: under;
}
.main-chart .main-title-text div {
  align-items: center;
}
.main-chart .main-title-share {
  margin-left: 1px;
}
.main-chart .main-title-share i {
  margin-top: 3px;
}
