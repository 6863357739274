.selector {
  width: 90%;
  margin: 0;
  margin-top: 20px;
}
.selector :global .ant-select-selection {
  background-image: linear-gradient(to bottom, var(--selector-ant-selection-bg-color-from, #f8f8f8), var(--selector-ant-selection-bg-color-to, #fcfcfc));
  border: solid 1px var(--selector-ant-selection-border-color, #ddd);
  color: var(--selector-ant-selection-text-color, #3072c5);
  padding: 0 10px;
}
.selector :global .ant-select-arrow {
  top: 30%;
}
.block {
  border-right: dotted 2px var(--selector-block-border-color, #ababab);
  color: var(--selector-block-text-color, #808080);
}
