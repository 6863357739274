.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
:global #userQuota {
  padding: 5px;
  overflow: hidden;
}
:global #userQuota .highcharts-plot-band {
  cursor: pointer;
}
:global #userQuota .a10-chart-content {
  top: 0px;
}
.userQuotaDropdown {
  float: right;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  z-index: 5;
  font-size: 12px;
}
.userQuotaDropdown :global span.ant-dropdown-trigger svg {
  width: 12px;
  height: 12px;
  margin-left: 3px;
}
.dropdownMenu {
  display: flex;
  flex-direction: row;
  float: right;
}
.inkBar {
  height: 3px !important;
}
.activeInkBar {
  background-image: linear-gradient(to right, #6fc4e7 14%, #3c92ca 85%);
}
.menuTitle {
  transition: font-weight 0.2s ease-in-out;
  cursor: pointer;
}
.activeTitle {
  color: #2c2c2c;
  font-weight: 500;
}
.inactiveTitle {
  color: #2c2c2c;
  font-weight: 400;
}
.menuMarginRight {
  margin-right: 24px;
}
.subscriberPortWrapper {
  display: flex;
  flex-direction: column;
  overflow: visible;
}
