.a10-sform-field-addons {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  margin-left: 10px;
  display: inline-block;
}
.a10-sform-field-helpIcon {
  color: #929292;
}
.a10-sform-field-helpIcon-configlist {
  margin-left: 3px;
  color: #929292;
  font-weight: normal;
  position: absolute;
  transform: translateY(12%);
}
.a10-sform-field-help {
  line-height: 18px;
  border-bottom: 1px dashed transparent;
  cursor: help;
}
.a10-sform-field-help:hover {
  border-bottom: 1px dashed #4a90e2;
}
.a10-sform-field-hidden {
  display: none;
}
.a10-sform-field-label {
  width: 100%;
  line-height: 20px;
  z-index: 1;
}
.a10-sform-field-label.config-list-label {
  display: flex;
  font-weight: 600;
  color: #1b1b1b;
}
.a10-sform-field-label.config-list-label:after {
  content: '';
  flex: 1 1;
  border-bottom: 1px solid #f2f2f2;
  margin: auto 150px auto 16px;
}
.a10-sform-field-label-rest-container {
  white-space: pre-wrap;
}
.a10-sform-field-label-last-container {
  display: inline-block;
}
.a10-sform-field-help-icon.a10-widget-appicon {
  position: relative;
  top: 2px;
  left: 2px;
  margin: 0 3px;
}
.a10-sform-field-help-icon.a10-widget-appicon > svg {
  transform: scale(1.6);
}
.a10-sform .ant-select-disabled .ant-select-selection {
  border-color: #d9d9d9;
}
.a10-sform .sform-z-index-1 {
  z-index: 1;
}
.a10-sform .sform-z-index-2 {
  z-index: 2;
}
.sform-field-required {
  width: 4px;
  height: 4px;
  border-radius: 100px;
  background-color: #e57373;
  display: inline-block;
  vertical-align: text-top;
  margin-left: 1px;
}
