/* prettier-ignore */
.dgf-help-center-toggler {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}
.dgf-help-center-toggler svg {
  font-size: 24px;
}
.dgf-help-center-drawer.ant-drawer {
  z-index: 1031;
}
.dgf-help-center-drawer .ant-drawer-content {
  background-color: #f9f9f9;
}
.dgf-help-center-drawer-title {
  font-size: 26px;
  font-weight: bold;
  color: #363636;
}
.dgf-help-center-drawer-description {
  margin-bottom: 23px;
  font-size: 20px;
  font-weight: 300;
  color: #363636;
}
.dgf-help-center-drawer-panel {
  margin-top: 16px;
  background-color: #fff;
  border: 1px solid #e7ecf1;
  border-radius: 4px;
}
.dgf-help-center-drawer-panel-header {
  padding: 0 14px;
  font-size: 16px;
  font-weight: 500;
  color: #363636;
  line-height: 60px;
  border-bottom: 1px solid #e7ecf1;
}
.dgf-help-center-drawer-panel-header span {
  margin-right: 12px;
}
.dgf-help-center-drawer-panel-body {
  padding: 32px 16px;
}
.dgf-help-center-supports-item .a10-widget-appicon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  width: 100px;
  height: 100px;
  border-radius: 3px;
  background-color: #f3f9ff;
}
.dgf-help-center-supports-item svg {
  font-size: 30px;
}
.dgf-help-center-supports .dgf-help-center-supports-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.dgf-help-center-about .dgf-help-center-drawer-panel-body {
  padding: 32px;
}
.dgf-help-center-about-title {
  font-weight: 700;
}
.ant-drawer.dgf-help-center-drawer {
  /* larger than log panel */
  z-index: 1031;
}
