.standalone-style {
  padding: 0 10px;
  height: 37px;
  display: flex;
  align-items: center;
  background-color: var(--timeline-bg-color, #fcfcfc);
  box-shadow: 0 2px 2px 0 rgba(218, 218, 218, 0.5);
  border-top: 1px solid var(--timeline-border-color, #64b5f6);
}
.flex {
  position: relative;
  display: flex;
  align-items: center;
}
.date-label {
  margin-left: 5px;
  font-weight: var(--font-weight-medium, 500);
}
.time-slider {
  display: flex;
  flex: 1;
  padding-left: 20px;
}
.periodButtons {
  display: flex;
}
.radius-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  border: solid 1px var(--timeline-radius-button-bg-color, #d2d2d2);
  border-radius: 32px;
  font-weight: bold;
  color: var(--timeline-radius-button-text-color, #999);
  background: transparent;
}
.radius-button svg {
  width: 12px;
  height: 12px;
}
.clock {
  position: absolute;
  left: 4px;
  width: 20px;
  height: 23px;
  background-image: url('../assets/real-time-live@2x.png');
  background-repeat: no-repeat;
  background-size: 20px;
}
.repeat-bar-popover :global .ant-popover-inner-content {
  display: flex;
  padding: 6px;
}
.repeat-bar {
  display: flex !important;
  align-items: center !important;
  border: 1px solid var(--timeline-radius-repeat-bar-border-color, #d5d5d5) !important;
  padding: 0px 5px !important;
  height: 30px !important;
  border-radius: 32px !important;
  color: var(--timeline-radius-repeat-bar-color, black) !important;
}
.repeat-bar button {
  display: flex;
  align-items: center;
}
.repeat-bar :global .a10-widget-appicon {
  display: flex;
}
.text {
  margin-right: 4px;
  font-size: 12px;
}
.refresh-down svg {
  width: 8px;
  height: 8px;
}
.date-picker {
  display: flex;
  align-items: center;
  border: none !important;
  background: transparent !important;
  display: inline-flex !important;
  margin: 0 !important;
  color: var(--timeline-date-picker-color, black);
}
.date-picker .calendar-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
.date-picker .arrow svg {
  width: 10px;
}
.date-picker button {
  display: flex;
  align-items: center;
}
.date-picker-popover :global .ant-popover-inner-content {
  display: flex;
  padding: 6px;
}
.date-picker-popover .menu {
  display: flex;
  flex-direction: column;
}
.date-picker-popover .menu ul {
  padding: 0;
  margin: 0;
  min-width: 150px;
  color: var(--timeline-date-picker-dropdown-color, #000);
}
.date-picker-popover .menu ul hr {
  border: none;
  border-top: solid 1px var(--timeline-date-picker-dropdown-divider-color, #ebebeb);
  margin: 7px 0px;
  cursor: default;
}
.date-picker-popover .menu ul > li {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  border: solid 1px transparent;
  padding: 5px 12px !important;
  line-height: 22px !important;
  transition: all 0.3s;
}
.date-picker-popover .menu ul > li:hover {
  background-color: var(--timeline-date-picker-dropdown-item-em-bg-color, #d1e7fd);
  border: 1px solid var(--timeline-date-picker-dropdown-item-em-border-color, #afd7ff);
  cursor: pointer;
}
.date-picker-popover .menu button {
  margin-top: 4px;
}
.date-picker-popover .date-picker-container {
  margin-left: 28px;
}
.date-picker-popover .date-picker-container > div {
  position: relative !important;
}
.date-picker-popover .range-picker {
  position: relative;
  left: 0 !important;
  top: 0 !important;
}
.date-picker-popover .range-picker > div {
  box-shadow: none;
}
