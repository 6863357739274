.header {
  box-shadow: 0 0 4px 0 rgba(190, 190, 190, 0.5);
  border: solid 1px #e7ecf1;
  background-color: #fbfbfb;
}
.box {
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
}
.input-container {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  padding: 10px 0;
}
.drag-bars {
  position: absolute;
  cursor: grab;
  top: 0;
  left: 50%;
  border: 1px solid #fff;
}
.drag-bars:hover {
  border: 1px solid #ccc;
  border-radius: 4px;
}
.drag-bar {
  background-color: #aaa;
  width: 30px;
  height: 3px;
  margin: 2px;
}
.help {
  background-color: #4a90e2;
  border-radius: 100px;
  color: #fff;
  width: 24px;
  height: 24px;
  padding-top: 2px;
  text-align: center;
  cursor: pointer;
  margin-left: 8px;
  border: 1px solid #ccc;
}
.help:hover {
  box-shadow: 4px 1px 2px 0 rgba(190, 190, 190, 0.5);
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
