:global .indicator-value-text {
  font-size: 32px;
}
:global .indicator-value-unit {
  font-size: 18px;
  margin-left: 5px;
}
:global .indicator-value-divider {
  margin: 0 5px;
}
:global .indicator-value-icon {
  font-size: 11px;
  position: relative;
  bottom: 3px;
}
:global .indicator-value-icon svg {
  bottom: 0px;
}
.green {
  color: var(--indicator-value-success-color, #00aa6c);
}
.yellow {
  color: var(--indicator-value-warning-color, #fa8c16);
}
.red {
  color: var(--indicator-value-error-color, #ff2e48);
}
