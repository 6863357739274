.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.subscriberPortRange {
  height: 250px;
}
.subscriberPortRange :global .ant-alert-message {
  font-size: 12px;
}
.subscriberPortRange :global .ant-alert-icon {
  top: 11.5px;
}
.subscriberTitle {
  width: 75%;
}
.subscriberTitleText {
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
}
.bold {
  font-weight: 500;
}
.select {
  width: 25%;
  text-align: right;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: normal;
  color: #363636;
}
.select :global .ant-dropdown-trigger {
  font-size: 12px;
  cursor: pointer;
}
.select :global .ant-dropdown-trigger svg {
  width: 12px;
  height: 12px;
  margin-left: 3px;
}
.subscriberHeader {
  display: flex;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: normal;
  color: #363636;
  margin-bottom: 11px;
}
.alerts {
  margin-bottom: 11px;
}
.menuCheck {
  width: 20px;
  text-align: right;
  min-width: 9px;
  margin-left: 5px;
}
.menuCheck :global span.a10-widget-appicon {
  position: relative;
  top: 3px;
}
.menuName {
  width: fit-content;
  text-overflow: ellipsis;
  overflow: hidden;
}
.menuContent {
  display: flex;
}
.alertHolder {
  height: 50px;
  width: 100%;
}
