.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.grid-content {
  padding: 0 14px;
  overflow-y: scroll;
  height: calc(60% + 20px) !important;
}
.grid-content > div {
  max-width: 20%;
  word-break: break-word;
}
.item {
  padding: 20px;
  margin: 1px;
  height: 50%;
}
.value {
  font-size: 24px;
  font-weight: 500;
  color: var(--chart-grid-value-color, #ffffff);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text {
  color: var(--chart-grid-value-color, #ffffff);
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
