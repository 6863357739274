.a10-configlist {
  display: inline-block;
}
.a10-configlist__addlink {
  cursor: pointer;
}
.a10-configlist__add,
.a10-configlist__remove {
  cursor: pointer;
  color: #979797;
  border-color: #979797;
  vertical-align: middle;
}
.a10-configlist__add {
  margin-right: 10px;
}
.a10-configlist__remove {
  margin-left: 20px;
}
.a10-configlist-removecol {
  vertical-align: middle !important;
}
.a10-configlist-row {
  border-left: 4px solid #e7ecf1;
  padding-left: 10px;
  border-radius: 4px;
  margin-bottom: 16px;
}
.a10-configlist-item-header {
  margin: 10px 0;
  line-height: 20px;
  font-weight: normal;
  color: #2a2a2a;
}
.a10-configlist .a10-sform-field-helpIcon {
  margin-left: 3px;
}
