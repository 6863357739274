.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.content :global .a10-chart-summary-header {
  display: none;
}
.content :global .a10-chart-summary {
  border: none;
}
