.dropsBarChartContainer,
.dropsBarCaption {
  height: 100% !important;
}

.dropsBarChartContainer {
  width: 100%;
}

.dropsBarChart {
  height: 220px;
}

.dropsBarCaption .viz-chart-header {
  font-size: 14px;
  padding-left: 10px !important;
  text-decoration: none;
  font-weight: 400;
}

.dropsBarCaptionRow {
  color: #686868 !important;
}

.dropsBarCaptionLabel {
  position: relative;
  top: 4px;
  left: 14px;
  font-size: 12px;
  font-weight: 500;
  width: 150px;
}

.dropsBarCaption {
  background: rgba(239, 239, 239, 0.45);
}
