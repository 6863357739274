.expander-container .expander-row {
  border-top: solid 1px rgba(217, 226, 237, 0.5);
  border-bottom: solid 1px rgba(217, 226, 237, 0.5);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 56px;
}
.expander-container .expander-row.is-prepare {
  flex-flow: row wrap;
}
.expander-container .expander-row:first-child {
  border-top: none;
}
.expander-container .expander-row:last-child {
  border-bottom: none;
}
.expander-content {
  flex: auto 0 1;
  padding-right: 40px;
}
.expander-content .label {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  margin-right: 20px;
  white-space: nowrap;
}
.expander-content .value {
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: bottom;
}
.expander-content .value.long-string {
  max-width: 800px;
}
