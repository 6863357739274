.trace-table-type-tag {
  padding: 4px;
  text-align: center;
}
.trace-table .long-word {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trace-table .traceTableInfo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.trace-table .traceTableItem .itemKey {
  font-weight: 500;
  margin-right: 15px;
  white-space: nowrap;
}
.trace-table .traceTableItem .itemValue {
  margin-right: 30px;
  white-space: nowrap;
}
