.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.SSLIUrls {
  position: relative;
  width: 100%;
  height: 100%;
}
.sortBy {
  display: flex;
  width: 200px;
  margin-right: 30px;
  float: right;
  color: var(--SSLiUrlCategories-sortBy-text-color, #000000);
  margin-top: 20px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-weight: 500;
}
.sortByRow {
  width: 140px;
  min-width: 140px;
  cursor: pointer;
}
.sortByCaption {
  white-space: nowrap;
  font-size: 14px;
  min-width: 63px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-weight: 300;
}
.activeSort {
  color: var(--SSLiUrlCategories-activeSort-text-color, #4a90e2);
}
.inactiveSort {
  color: var(--SSLiUrlCategories-inactiveSort-text-color, #999);
}
.sortByIcon {
  display: inline-block;
  width: 15px;
  margin-right: 8px;
  white-space: nowrap;
}
.sortByTitle {
  width: 63px;
}
.categories {
  display: flex;
  width: 100%;
  height: 100%;
}
.category {
  width: 50%;
  padding-left: 72px;
  padding-top: 36.5px;
}
.categoryTitle {
  font-size: 19px;
  color: var(--SSLiUrlCategories-categoryTitle-text-color, #000000);
  margin-bottom: 40px;
}
.subCategories {
  height: 50px;
  color: var(--SSLiUrlCategories-subCategories-text-color, #000000);
  display: flex;
  margin-bottom: 14px;
}
.subCategoryViz {
  width: 95%;
  display: flex;
  flex-direction: column;
}
.subCategoryTitle {
  width: 170px;
  min-width: 170px;
  margin-right: 25px;
  white-space: nowrap;
}
.bar {
  display: inline-block;
  height: 24px;
  border-radius: 2px;
}
.inactiveBar {
  opacity: 0.3;
}
.volViz {
  width: 100%;
  display: flex;
}
.connViz {
  width: 100%;
  display: flex;
  margin-bottom: 3px;
}
.vizLetter {
  color: var(--SSLiUrlCategories-vizLetter-text-color, #999);
  font-size: var(--font-size-sm, 12px);
  width: 14px;
}
.caption {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-weight: 300;
  background-color: var(--SSLiUrlCategories-caption-bg-color, #999);
  height: 24;
  white-space: nowrap;
  border-radius: 2px;
  margin-left: 4px;
  padding: 0 5px;
  color: var(--SSLiUrlCategories-caption-text-color, #ffffff);
}
