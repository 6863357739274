.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.item {
  text-align: center;
  margin-bottom: 20px;
}
.item-title {
  font-size: var(--font-size-lg, 16px);
  color: var(--OverallChart-item-title-text-color, #2c2c2c);
  font-weight: 300;
}
.item-count {
  font-size: 22px;
  font-weight: 500;
  color: var(--OverallChart-item-count-text-color, #00aa6c);
}
.item-chart {
  margin: 0 auto;
  width: 170px !important;
}
.summary-counter {
  font-size: 42px;
  font-weight: bold;
  color: var(--OverallChart-item-title-text-color, #2c2c2c);
}
.summary-unit {
  font-size: 21px;
  font-weight: 300;
  color: var(--OverallChart-item-title-text-color, #2c2c2c);
}
.summary-title {
  font-size: var(--font-size-lg, 16px);
  color: var(--OverallChart-summary-title-text-color, #4a4a4a);
}
