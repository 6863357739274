.flow {
  position: relative;
  font-size: 14px;
}
.flow-bwrapper {
  border-radius: 8px;
  background-color: #ffffff;
  border: solid 1px #d2d2d2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  text-align: center;
  height: 82px;
}
.flow-lwrapper {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border: solid 1px #d2d2d2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  display: inline-block;
  height: 82px;
}
.flow-left {
  position: absolute;
  left: 0;
}
.flow-center {
  position: absolute;
  left: 290px;
  text-align: center;
}
.flow-right {
  position: absolute;
  left: 700px;
}
.flow-line {
  position: absolute;
  top: 75px;
  width: 119px;
  left: 170px;
  border: 1px #7ed321 dashed;
}
.flow-abspan {
  margin: auto;
  background-color: #ffffff;
  color: #fff;
  display: inline-block;
  padding: 2px 4px;
}
.flow-abspan2 {
  position: absolute;
  width: 80px;
  top: 64px;
  left: 600px;
  color: #fff;
  text-align: center;
}
.flow-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
  line-height: 1.2;
}
.flow-event-inner-msg {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  width: 310px;
  margin: 4px 0px;
  -webkit-box-orient: vertical;
  line-height: 1.3;
}
.flow-icon {
  position: absolute;
  top: 65px;
}
.flow-action-icon {
  width: 56px;
  display: inline-block;
  text-align: center;
}
.flow-audit {
  padding-top: 8px;
}
.flow-event-inner {
  padding: 0 8px;
}
.flow-event-up-lebal {
  position: absolute;
  top: 44px;
  left: 300px;
}
.flow-event-up-lebal span {
  font-weight: 500;
}
.flow-event-down-lebal {
  position: absolute;
  top: 68px;
  left: 300px;
}
.flow-event-down-lebal span {
  font-weight: 500;
}
.ellipsis-text-overflow,
.pull-up-text-overflow {
  width: 270px;
  overflow: hidden;
  /* 超出长度的部分隐藏 */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 100%;
  line-height: 1.2;
}
.ellipsis-text-overflow-short {
  overflow: hidden;
  /* 超出长度的部分隐藏 */
  text-overflow: ellipsis;
  /* 超过长度，末尾已 ... 补全  */
  white-space: nowrap;
  width: 150px;
}
