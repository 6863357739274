@import '~react-grid-layout/css/styles.css';
@import '~react-resizable/css/styles.css';
.gridLayout {
  background: var(--GridLayout-bg-color, #f9f9f9);
}
.gridLayout :global .react-draggable .a10-chart-header,
.gridLayout :global .react-draggable .viz-chart-header {
  cursor: move;
  text-align: center;
}
.gridLayout :global .react-grid-placeholder {
  background: var(--GridLayout-placeholder-bg-color, #a4cae4);
  border: 2px dashed var(--GridLayout-placeholder-border-color, #4a90e2);
}
.gridLayout :global .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.gridLayout :global .react-resizable-handle:after {
  content: '';
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 7px;
  height: 7px;
  border-right: 2px solid var(--GridLayout-handle-border-color, #ccc);
  border-bottom: 2px solid var(--GridLayout-handle-border-color, #ccc);
}
.chartsCanvas {
  background: var(--GridLayout-canvas-bg-color, #f9f9f9);
}
.chartPanel {
  border-radius: 4px;
  background-color: var(--GridLayout-panel-bg-color, #fff);
  height: 100%;
  overflow-y: auto;
}
