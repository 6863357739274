.netflow--monitor-form-loading {
  width: 100%;
  height: 100vh;
  position: relative;
}
.netflow--monitor-form-loading--icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.netflow--monitor-form div.ant-form-explain {
  margin-top: 2px;
  z-index: 100;
  color: #ff5e72;
  min-width: 250px;
  word-break: break-word;
}
.netflow--monitor-form .ant-form-item .ant-switch {
  margin-left: 8px;
}
.netflow--monitor-form .ant-input-number {
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #afd7ff;
}
.netflow--monitor-form--inner-field .ant-form-item {
  margin-bottom: 0 !important;
}
.netflow--monitor-form--custom-events {
  line-height: initial;
  margin-bottom: 16px;
}
.netflow--monitor-form--custom-events .delete-another {
  margin-top: 8px;
  cursor: pointer;
}
.netflow--monitor-form--custom-events .add-another {
  cursor: pointer;
  background: none;
}
.netflow--monitor-form--custom-events .add-another span {
  color: #4a90e2;
}
.netflow--monitor-form .ant-collapse-header {
  font-size: 18px;
}
.netflow--monitor-form .ant-collapse-item {
  padding-left: 0px;
}
.netflow--monitor-form .ant-collapse-item .ant-collapse-arrow {
  margin-left: 8px;
  margin-right: 5px;
}
.netflow--monitor-form .formated-form-title {
  margin-bottom: 5px;
  font-weight: 500;
  color: #363636;
}
.netflow--monitor-form .formated-form-des {
  font-weight: 500;
  color: #363636;
  margin-bottom: 34px;
}
.netflow-disable-log-add {
  color: #2a6496;
  cursor: pointer;
}
.netflow-disable-log-wrapper {
  border-left: 4px solid #afd7ff;
  padding-left: 8px;
  border-radius: 4px;
}
