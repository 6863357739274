.container {
  display: inline-block;
  padding-top: 2px;
  padding-left: 8px;
}
.container :global .fa {
  margin: 0 3px;
  padding: 2px;
  border-radius: 3px;
  border: solid 1.5px #dedede;
  cursor: pointer;
}
.container :global div {
  display: inline-block;
}
.actived {
  padding-bottom: 3px;
  border-bottom: 3px solid #4a90e2;
}
.actived :global span {
  border-radius: 3px;
  border: solid 1.5px #4a90e2 !important;
}
.actived :global svg {
  fill: #242424;
}
