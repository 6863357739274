.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.progressBars {
  overflow: auto;
  width: calc(100% - 28px);
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-left: 14px;
  margin-right: 14px;
}
.progressBar {
  border-radius: 5px;
  display: flex;
  width: 100%;
  height: 12px;
  flex-direction: column;
  position: relative;
}
.progress {
  border-radius: 5px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 12.3px;
  position: absolute;
}
.progressInfo {
  position: relative;
  bottom: 200%;
}
.progressName {
  width: fit-content;
}
.progressUnits {
  width: fit-content;
  float: right;
}
