.contrast-chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
}
.title {
  color: inherit;
}
.chart-title {
  font-size: 12px;
  font-weight: 500;
  font-family: Roboto;
  color: #000000;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.left-title {
  font-size: 12px;
  text-align: right;
}
.right-title {
  font-size: 12px;
  text-align: left;
}
.flex-container {
  margin-bottom: 6px;
  flex: 1;
  display: flex;
  flex-flow: nowrap row;
  justify-content: center;
  align-content: center;
  overflow-y: auto;
  overflow-x: hidden;
}
.chart-container {
  flex: 0% 1 1;
  min-width: 50px;
}
.x-axis-container {
  margin: 0 15px;
  flex: auto 0 0;
}
.x-axis-container > div {
  text-align: center;
}
.x-axis-label {
  font-size: 12px;
  font-weight: normal;
}
.stack-labels {
  font-size: 12px;
  font-weight: 300;
  color: #ffffff;
  padding: 3px 5px;
  background: #919191;
  border-radius: 2px;
}
.left-offset {
  position: relative;
  left: -100%;
  margin-right: 5px;
}
.right-offset {
  margin-left: 5px;
}
.shared-legend {
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 100%;
}
.legend-label {
  color: #2c2c2c;
  font-size: 12px;
  font-weight: normal;
  padding-left: 4px;
  padding-right: 8px;
  vertical-align: top;
}
.legend-button {
  border-radius: 2px;
  color: #2b2b2b;
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
  margin-right: 4px;
  padding: 2px 5px;
  vertical-align: top;
}
.disabled-legend {
  background-color: #fcfcfc !important;
  border: 1px solid #efefef !important;
  color: #d2d2d2 !important;
}
.legend-reset {
  border-left: 1px dashed #cfc9c9;
  color: #969696;
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
  padding: 0 6px;
  margin-left: auto;
  vertical-align: top;
}
