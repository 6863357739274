.autoform {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
.autoform > .ant-row {
  margin: 0 !important;
  overflow: auto;
  padding-bottom: 40px;
  flex: 1 0 auto;
}
.autoform > .ant-row .a10-sform-body {
  padding-bottom: 0;
}
@supports (-moz-appearance: meterbar) {
  .autoform > .ant-row-last {
    padding-bottom: 100px;
  }
}
.autoform:not(.autoform-simple-table) > .ant-row > .ant-col-12:first-child {
  padding-left: 0 !important;
}
.autoform-action-buttons {
  padding: 10px 30px;
  background: #f4f4f4;
  bottom: 0;
  right: 0;
  left: 0;
  position: sticky;
  flex: 0 0 72px;
  box-shadow: 0 -1px 4px 0 rgba(197, 197, 197, 0.5);
  z-index: 900;
}
@supports (-moz-appearance: meterbar) {
  .autoform-action-buttons {
    position: fixed;
  }
}
.autoform-addons {
  padding: 0 30px;
}
.autoform .ant-table-bordered .ant-table-title,
.autoform .autoform-addons .ant-table-title {
  border: none !important;
  padding: 0 0 9px 0;
}
.autoform .ant-table-bordered .ant-table-placeholder,
.autoform .autoform-addons .ant-table-placeholder {
  border-radius: 0 0 4px 4px;
}
.autoform .ant-table-bordered .ant-table-title + .ant-table-content .ant-table-body,
.autoform .autoform-addons .ant-table-title + .ant-table-content .ant-table-body {
  border-radius: 4px 4px 0 0;
}
.ant-modal-body .a10-sform-body {
  padding-bottom: 0;
}
.ant-modal-body .autoform > .ant-row {
  min-height: auto;
}
.ant-modal-body .autoform-action-buttons {
  z-index: 10000;
}
