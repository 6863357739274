.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.line-chart {
  width: 100%;
}
.line-chart :global .highcharts-scrolling {
  overflow: hidden !important;
}
.line-chart :global .a10-chart-content {
  top: 0px;
}
.chartLabel {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  color: var(--linechart-label-text-color, #999);
}
.chartValue {
  font-size: 42px;
  font-weight: 500;
  text-align: center;
}
.unit {
  font-size: 21px;
}
