.table :global .ant-table-body {
  margin-bottom: 0;
}
.table :global .ant-table-thead > tr > td,
.table :global .ant-table-tbody > tr > td {
  color: #686868;
}
.table :global .ant-table-bordered {
  border: none !important;
}
.table :global .ant-table-content {
  border: 1px solid #cfd9e2;
  border-radius: 4px !important;
}
.title {
  display: flex;
  justify-content: space-between;
  margin: 89px 0 0;
}
.title > span {
  font-family: Rubik, system-ui, BlinkMacSystemFont, 'Helvetica Neue', Arial, sans-serif;
  font-size: 24px;
  font-weight: normal;
}
.tools {
  display: flex;
  justify-content: right;
  align-items: center;
}
.action :global .ant-btn:not(:last-child),
.tools :global .ant-btn:not(:last-child) {
  margin-right: 10px;
}
.modal :global .ant-modal-close-x {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cell {
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  word-break: break-all;
}
.loading {
  height: 50vh;
}
.deleteTag {
  word-break: break-all;
  white-space: normal;
  height: auto;
}
.warn {
  margin-top: 15px;
}
