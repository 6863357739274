.filterBar {
  background-color: #f8f7f7;
  box-shadow: 0 1px 1px 0 rgba(228, 228, 228, 0.5);
  border: solid 1px #e4e4e4;
  border-top: 0;
  min-height: 60px;
  padding: 0 15px;
}
.filterBar :global .ant-calendar-picker {
  display: none;
}
.vipSelect {
  width: 90%;
  margin: 0;
}
.vipSelect :global .ant-select-selection {
  background-image: linear-gradient(to bottom, #fefefe, #faf6f6);
  border: solid 1px #dfdfdf;
  color: #0074c1;
  padding: 0 10px;
}
.vipSelect :global .ant-select-arrow {
  top: 30%;
}
.vipBlock {
  border-right: dotted 2px #a6a6a6;
  color: #898989;
}
.date-picker {
  border: none !important;
  background: transparent !important;
  display: inline-flex !important;
}
.date-picker :global .anticon {
  color: #949494;
}
.date-picker-dropdown {
  min-width: 40px;
}
.calender {
  width: 20px !important;
  height: 20px !important;
  background-image: url('../images/calendar-2@2x.png');
  background-repeat: no-repeat;
  background-size: 20px 20px;
  min-width: 20px;
}
.calender-arrow {
  font-size: 9px;
  vertical-align: top;
}
.date-label {
  margin-left: 10px;
  min-width: 13em;
  max-width: 20em;
}
.slider {
  display: inline-block;
  margin: 0 35px 0 10px !important;
  height: 16px !important;
  flex-grow: 1 !important;
  max-width: 70% !important;
}
.slider > div[class*='ant-slider-rail'] {
  background-color: #e4e4e4;
}
.date-block {
  display: flex !important;
  align-items: center !important;
  padding-left: 15px !important;
}
.radius-button {
  border-radius: 32px !important;
  padding: 0 4px !important;
  margin: 0 5px !important;
  min-width: 34px !important;
  border: solid 1px #d5d5d5 !important;
  height: 34px !important;
  font-weight: bold;
  color: #949494 !important;
  background: transparent !important;
}
.time-picker {
  margin-left: 30px !important;
}
.clock {
  background-image: url('../images/real-time-live@2x.png');
  background-repeat: no-repeat;
  background-size: 18px 21px;
  width: 18px;
  height: 21px;
  margin: 0 auto;
}
