.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.chart-indicator {
  width: 100%;
  height: 100%;
}
.chart-indicator :global .bottom {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.chart-indicator :global .left {
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}
.chart-indicator :global .left .units {
  margin-right: 10px;
}
.chart-indicator :global .right {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.chart-indicator :global .right .units {
  margin-left: 10px;
}
.chart-indicator :global .top {
  flex-direction: column-reverse;
}
.divider {
  border-bottom: 1px solid var(--indicator-divider-border-color, #000);
  height: 3px;
}
.oneIndicator {
  display: flex;
}
.oneIndicator :global .indicator-units {
  font-family: Roboto;
  font-size: 36px;
  font-weight: normal;
  color: var(--indicator-item-text-color, #00aa6c) !important;
  text-align: center;
}
.oneIndicator :global .indicator-item {
  font-size: 100px;
  color: var(--indicator-item-text-color, #00aa6c) !important;
  text-align: center;
}
.twoIndicators {
  display: flex;
}
.twoIndicators :global .indicator-units {
  font-family: var(--font-family, Roboto);
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: var(--indicator-unit-text-color, #4a4a4a);
}
.twoIndicators :global .indicator-item {
  font-family: var(--font-family, Roboto);
  font-size: 32px;
  text-align: center;
  color: var(--indicator-item-text-color, #00aa6c) !important;
}
.threeIndicators {
  display: flex;
}
.threeIndicators :global .indicator-units {
  font-family: var(--font-family, Roboto);
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: var(--indicator-unit-text-color, #4a4a4a);
}
.threeIndicators :global .indicator-item {
  font-family: Roboto;
  font-size: 32px;
  text-align: center;
  color: var(--indicator-item-text-color, #00aa6c) !important;
}
.indicatorWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}
