.netflow--template-form-loading {
  width: 100%;
  height: 100vh;
  position: relative;
}
.netflow--template-form-loading--icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.netflow--template-form .ant-input-number {
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #afd7ff;
}
.netflow--template-form--multiple-selection {
  border: 1px solid #ebebeb;
  border-radius: 0 0 4px 4px;
}
.netflow--template-form--multiple-selection--header {
  padding: 16px;
}
.netflow--template-form--multiple-selection--header h2 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}
.netflow--template-form--multiple-selection--body {
  padding: 16px;
  border-top: 1px solid #ebebeb;
}
.netflow--template-form--multiple-selection--body .selection {
  padding-bottom: 6px;
}
.netflow--template-form--multiple-selection + .netflow--template-form--multiple-selection {
  margin-top: 16px;
}
.netflow--template-form .ant-collapse-header {
  font-size: 18px;
}
.netflow--template-form .ant-collapse-item {
  padding-left: 0px;
}
.netflow--template-form .ant-collapse-item .ant-collapse-arrow {
  margin-left: 8px;
  margin-right: 5px;
}
.netflow--template-form .formated-form-title {
  margin-bottom: 5px;
  font-weight: 500;
  color: #363636;
}
.netflow--template-form .formated-form-des {
  font-weight: 500;
  color: #363636;
  margin-bottom: 34px;
}
