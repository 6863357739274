.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.content {
  padding: 10px 15px;
}
.total {
  font-size: 42px;
  font-weight: bold;
  text-align: center;
  color: var(--multichart-total-text-color, #2c2c2c);
}
.row {
  margin-bottom: 50px;
}
.row:last-child {
  margin-bottom: 0;
}
.chartLabel {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  color: var(--multichart-label-text-color, #4a4a4a);
}
.chartValue {
  font-size: 42px;
  font-weight: 500;
  text-align: center;
}
.unit {
  font-size: 21px;
}
