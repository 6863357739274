.tabs:first-child {
  float: left;
}
.tab {
  overflow: hidden;
  background-color: inherit;
  border: none;
  border-bottom: 2px solid transparent;
  margin: 10px 15px;
  padding: 0 24px 4px 2px;
  outline: none;
  color: var(--tabs-tab-color, #363636);
  transition: all 0.3s;
}
.tab:hover {
  font-weight: var(--font-weight-medium, 500);
  cursor: pointer;
}
.active-tab {
  position: relative;
  color: var(--tabs-tab-em-color, #000);
  font-weight: var(--font-weight-medium, 500);
}
.active-tab::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: var(--tabs-tab-em-border-color);
}
.showAll {
  width: 100px;
  float: right;
  margin: 10px 15px;
  padding: 1px 6px;
  cursor: pointer;
}
.showAll span {
  border: 2px solid transparent;
  font-weight: bold;
}
