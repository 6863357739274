.chart-content {
  border-radius: 4px;
  border: solid 1px #e3e3e3;
  background-color: #ffffff;
  margin: 10px 0;
}
.title {
  text-align: center;
  font-weight: 500;
}
