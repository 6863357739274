.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  border: 1px solid var(--MainChart-loading-container-border-color, #e3e3e3);
  background: var(--MainChart-loading-container-bg-color, #fff);
}
.radius :global .a10-chart {
  border-radius: 4px;
}
.radius :global .a10-chart-filter-label {
  font-size: 12px;
}
.transparent :global .a10-chart {
  background: transparent;
}
:global .a10-chart {
  border: none;
}
:global .a10-chart-simple-header-chart {
  padding: 4px 0 0 15px;
}
:global .highcharts-axis-labels text {
  font-family: var(--font-family, Roboto);
  font-size: var(--font-size-lg, 16px);
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--MainChart-highcharts-axis-labels-text-color, #2c2c2c);
}
.debug-icon {
  position: absolute;
  z-index: 999;
  right: 10px;
  cursor: pointer;
}
:global .a10-chart-content-chart div.highcharts-annotation {
  width: auto;
  height: auto;
}
:global .horizontal-content {
  display: flex;
  scrollbar-color: #d2d2d2 transparent;
  scrollbar-width: thin;
}
:global .horizontal-content::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}
:global .horizontal-content::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #d2d2d2;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
:global .defaultTooltip,
:global .defaultContent {
  color: #f4f4f4;
  display: flex;
}
:global .defaultTooltip {
  display: flex;
  flex-direction: column;
}
:global .alertsWrapper {
  width: 110px;
}
:global .tooltip-separator {
  position: sticky;
  top: 0px;
  width: 0.5px;
  background-color: #f4f4f4;
  margin: 0px 12px;
}
:global .highcharts-tooltip-container svg.highcharts-root {
  opacity: 0.8;
}
:global .alertEvents {
  color: #f4f4f4;
  float: left;
  background-color: rgba(40, 40, 40, 0.7);
  border-radius: 4px;
  padding: 7px;
  width: 180px !important;
}
:global .alerts-header {
  width: 100%;
  color: #81d4fa;
  display: flex;
  flex-direction: column;
}
:global .alerts-content {
  margin-bottom: 3px;
}
:global .alertsEventsContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}
:global .alertsEventsCol {
  display: flex;
  width: 100%;
}
:global .alertEvent-count {
  width: 25% !important;
  color: #f4f4f4;
  text-align: right;
}
:global .alertEvent-title {
  width: 75%;
  color: #98c8ff;
}
:global .alert-border {
  border-top: 1px solid rgba(244, 244, 244, 0.8);
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  opacity: 0.8;
  margin: 10px 0;
}
:global .highcharts-label.highcharts-annotation-label {
  visibility: visible !important;
  opacity: 1 !important;
}
:global .highcharts-annotation-point.highcharts-label {
  width: 20px !important;
  height: 20px !important;
}
:global .highcharts-annotation-point.highcharts-label > span {
  left: 0px !important;
  top: 0px !important;
}
:global .a10-chart-content-chart div.highcharts-alerts-label {
  width: fit-content;
  height: fit-content;
}
:global .a10-chart-content-chart div.highcharts-alerts-label > span {
  left: 0px !important;
  top: 0px !important;
}
:global .highcharts-tooltip-container {
  z-index: 99;
}
:global .highcharts-insideLink {
  display: none;
}
:global .highcharts-globalLink {
  display: none;
}
