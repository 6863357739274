.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.destinationCountries {
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: hidden;
  padding-top: 5px;
}
.map {
  width: 60%;
}
.topCountries {
  width: 40%;
}
.topCountries :global .viz-chart-header {
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  padding: 12px 0 0 0;
}
.topCountries :global .viz-chart-header + div {
  height: calc(100% - 56px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 90%;
}
