.group :global i {
  vertical-align: middle;
  margin-right: 14px;
}
.group-disabled {
  cursor: not-allowed;
  color: #ccc;
}
.group-disabled a {
  cursor: not-allowed;
  color: #ccc;
}
.group-disabled a:hover {
  cursor: not-allowed;
  color: #ccc;
}
