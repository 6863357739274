.container {
  user-select: none;
  padding: 2px 10px;
  display: inline-block;
  border-bottom: 3px solid #00000000;
}
.container global:not(:last-child) {
  margin-right: 5px;
}
.label {
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}
.actived {
  color: #363636;
  border-bottom: 3px solid #4a90e2;
}
.badge {
  background: #999999;
  border-radius: 10px;
  padding: 2px 8px;
  margin-left: 8px;
  color: #fff;
  font-size: 12px;
}
.badge-actived {
  background-color: #363636;
}
