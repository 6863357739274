.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.content {
  padding: 5px;
  width: 100%;
  position: relative;
}
.subChart {
  position: absolute;
  left: 0;
  bottom: -20px;
  width: 230px;
  height: 230px;
}
