.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.list-content {
  padding: 0 14px;
  padding-top: 10px;
  overflow: auto;
  max-height: 100%;
  width: 100%;
}
.list-content :global .headers .ant-col {
  display: flex;
  align-items: center;
}
.list-content :global .headers .ant-col-12 {
  height: 24px;
}
.label {
  color: var(--ListChart-label-color, #fff);
  width: 18px;
  height: 18px;
  padding: 1px 6px;
  background-color: var(--ListChart-label-bg-color, #7ed321);
  border-radius: 11px;
  text-align: center;
  font-size: 12px;
  margin-right: 5px;
  display: inline-block;
  line-height: 16px;
}
.labelDetail {
  display: inline-block;
  font-size: 12px;
}
.sparkLine {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.sparkLine-chart {
  margin-top: -8px;
  margin-left: -9px;
  margin-right: -9px;
  height: 65px;
}
.listHeader {
  font-family: var(--font-family, Roboto);
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--ListChart-listHeader-text-color, #363636);
  overflow: hidden;
  text-overflow: ellipsis;
}
.listLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  font-size: 12px;
}
.alignLeft {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}
