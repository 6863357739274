.title {
  font-size: var(--font-size-lg, 16px);
  font-weight: normal;
  color: var(--chart-title-text-color, #000);
  padding: 12px 14px;
  text-decoration-style: dashed;
  text-decoration-color: var(--chart-title-decoration-color, #4a90e2);
  text-decoration-line: underline;
  text-underline-position: under;
}
.content {
  padding: 5px;
  width: 100%;
}
.subscriberPortUsage {
  height: 250px;
  overflow: visible;
}
.subscriberPortUsage :global g.highcharts-column-series rect.highcharts-point {
  display: none;
}
.subscriberPortUsage :global .a10-chart {
  width: 100%;
}
.subscriberPortUsage :global .a10-chart-content-chart div.highcharts-port-usage-tooltip {
  background-color: transparent;
}
.subscriberPortUsage :global .a10-chart-content-chart div.highcharts-port-usage-tooltip > span {
  width: fit-content;
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  background-color: #383838;
  opacity: 0.8;
  color: white;
}
.subscriberPortUsage :global .a10-chart-content-chart {
  z-index: 1;
  overflow: visible;
}
.subscriberPortUsage :global .a10-chart-content-chart div[data-highcharts-chart] {
  overflow: visible;
}
.subscriberPortUsage :global .a10-chart-content-chart div[data-highcharts-chart] div.highcharts-container {
  overflow: visible !important;
}
.subscriberPortUsage :global .a10-chart-content-filter {
  z-index: 0;
}
.subscriberPortUsage :global .a10-chart-wrapper,
.subscriberPortUsage :global .a10-chart-content,
.subscriberPortUsage :global .a10-chart {
  overflow: visible;
}
