.dgp-viz-loading {
  width: 100%;
  height: 100vh;
  position: relative;
}
.dgp-viz-loading__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
