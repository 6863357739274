.tableAction {
  min-width: 100% !important;
  cursor: pointer;
  overflow-y: auto;
  height: 100%;
  padding-top: 12px;
  max-height: 300px;
  overflow-y: auto;
}

.tableAction .ant-spin-nested-loading {
  position: relative !important;
  top: 0px !important;
}

.tableAction .ant-table {
  font-size: 12px !important;
}
