.searchFilter {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 16px !important;
  border: 1px var(--chart-title-decoration-color, #4a90e2) solid;
  padding: 0 10px;
  color: var(--logpanel-log-panel-td-text-color, #363636);
  height: 30px;
}
.searchFilter .ant-select,
.searchFilter .ant-select-selection {
  width: 100%;
  border: none;
  height: 21px;
  background-color: transparent;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.searchFilter .ant-select-selected-value,
.searchFilter .ant-select-selection-selected-value {
  display: none !important;
}
.searchFilter .ant-select-search,
.searchFilter .ant-select-selection-search {
  width: calc(100% - 40px);
}
.searchFilter .ant-select-search__field,
.searchFilter .ant-select-selection-search__field {
  height: 21px !important;
}
.searchFilter .ant-select--single,
.searchFilter .ant-select-selection--single {
  height: 21px !important;
}
.searchFilter .ant-select__rendered,
.searchFilter .ant-select-selection__rendered {
  height: 21px !important;
  line-height: 21px !important;
  margin-right: 0px !important;
  z-index: 50;
}
.searchFilter .ant-select__clear,
.searchFilter .ant-select-selection__clear {
  right: 10px !important;
  z-index: 50;
}
.searchFilter .ant-select__placeholder,
.searchFilter .ant-select-selection__placeholder {
  color: var(--logpanel-log-panel-td-text-color, #363636);
}
.searchFilter .ant-select-dropdown,
.searchFilter .ant-select-selection-dropdown {
  width: auto !important;
  max-width: 900px;
  min-width: 150px;
  left: 12px !important;
}
.searchFilter .ant-btn-block {
  border-color: transparent;
}
.searchFilter .ant-btn-block:hover {
  border-color: transparent;
  background-color: var(--logpanel-dropdown-hover-bgcolor, #d1e7fd);
}
.searchFilter .currentSearch {
  display: flex;
  align-content: center;
  flex: 2;
  height: 28px;
}
.searchFilter .currentSearch .selectingText {
  display: flex;
  align-items: center;
  max-width: 200px;
  padding: 0 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.searchFilterInput {
  min-width: 200px;
  width: 100%;
  margin: 3px;
}
.searchFilterList {
  width: 30px;
}
.searchFilterList .ant-select .ant-select-arrow,
.searchFilterList .ant-select-selection .ant-select-arrow {
  right: 7px;
}
.searchFilterList .ant-select__rendered,
.searchFilterList .ant-select-selection__rendered {
  margin: 0px;
}
.searchFilterList .ant-select-dropdown,
.searchFilterList .ant-select-selection-dropdown {
  width: 400px !important;
  height: auto !important;
  left: calc(100% - 400px) !important;
  overflow: auto;
}
.searchFilterList .ant-select-dropdown .ant-tree-node-content-wrapper,
.searchFilterList .ant-select-selection-dropdown .ant-tree-node-content-wrapper {
  width: calc(100% - 30px) !important;
}
.searchFilterList .ant-select-dropdown .ant-tree-node-content-wrapper.ant-tree-node-selected,
.searchFilterList .ant-select-selection-dropdown .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: transparent;
}
.searchFilterList .ant-select-dropdown .a10-filtertree-filterTitleContainer,
.searchFilterList .ant-select-selection-dropdown .a10-filtertree-filterTitleContainer {
  display: none !important;
}
.searchFilterList .ant-select-dropdown .ant-select,
.searchFilterList .ant-select-selection-dropdown .ant-select {
  height: auto;
}
.searchFilterList .ant-select-dropdown .ant-select-dropdown,
.searchFilterList .ant-select-selection-dropdown .ant-select-dropdown {
  width: auto !important;
  left: auto !important;
}
.searchFilterList .ant-select-dropdown .ant-select-selection,
.searchFilterList .ant-select-selection-dropdown .ant-select-selection {
  border: 1px var(--chart-title-decoration-color, #4a90e2) solid;
  height: auto;
}
.searchFilterList .ant-select-dropdown .ant-select-selection__rendered,
.searchFilterList .ant-select-selection-dropdown .ant-select-selection__rendered {
  height: auto !important;
  margin: 1px 3px;
}
.searchFilterList-expanded .ant-select-dropdown {
  width: 800px !important;
  left: calc(100% - 800px) !important;
}
.searchFilterList-dropdown {
  overflow: auto;
}
.searchFilterList-middle .ant-select-dropdown,
.searchFilterList-middle .ant-select-selection-dropdown {
  max-height: 300px;
}
.searchFilter .multipleSelect .switchOuter,
.searchFilter .inputSelect .switchOuter {
  margin: 12px;
  display: flex;
  align-content: center;
}
.searchFilter .multipleSelect .switchName,
.searchFilter .inputSelect .switchName {
  margin-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.searchFilter .multipleSelect .loaderOuter,
.searchFilter .inputSelect .loaderOuter {
  height: 20px;
  margin-top: -10px;
  margin-bottom: 40px;
}
.searchFilter .multipleSelect .loaderOuter .a10-loader-icon .ant-spin,
.searchFilter .inputSelect .loaderOuter .a10-loader-icon .ant-spin {
  height: auto;
}
.searchFilter .multipleSelectItem,
.searchFilter .inputSelectItem {
  max-height: 200px;
  overflow-y: auto;
}
.searchFilter .multipleSelectFooter hr,
.searchFilter .inputSelectFooter hr {
  border: none;
  border-bottom: 1px var(--logpanel-drawer-text-color, #d2d2d2) solid;
}
.searchFilter .multipleSelectSelectAll,
.searchFilter .inputSelectSelectAll {
  float: left;
  margin: 8px;
}
.searchFilter .multipleSelectDeselectAll,
.searchFilter .inputSelectDeselectAll {
  float: right;
  margin: 8px;
}
.searchFilter .multipleSelectKey,
.searchFilter .inputSelectKey {
  font-weight: 400;
  border-bottom: 1px var(--logpanel-drawer-text-color, #d2d2d2) solid;
  border-top: 1px var(--logpanel-drawer-text-color, #d2d2d2) solid;
  margin: 0 7px;
  padding: 0 4px;
}
.searchFilter .rangeSelect {
  margin: 10px;
}
.searchFilter .rangeSelect .rangeBar {
  width: 80%;
  margin: auto;
  padding: 20px 30px 20px 10px;
}
.searchFilter .rangeSelect .numberSelect {
  display: flex;
  justify-content: space-between;
}
.searchFilter .rangeSelect .numberSelectMin,
.searchFilter .rangeSelect .numberSelectMax {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 0 20px;
  text-align: center;
}
.searchFilter .rangeSelect .numberSelect .ant-input-number {
  width: auto;
}
.searchFilter .rangeSelect .numberSelect .ant-input-number-input {
  text-align: center;
}
.searchFilter .rangeSelect .valueDisplay {
  display: flex;
  justify-content: space-between;
}
.searchFilter .filterCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  background-color: var(--timeline-date-picker-dropdown-item-em-bg-color, #d1e7fd);
  padding: 0 5px;
  margin: 3px;
  height: 23px;
}
.searchFilter .filterCard .ant-select-dropdown {
  width: 450px !important;
  overflow-y: auto;
}
.searchFilter .filterCard-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 3px;
}
.searchFilter .filterCard-placeholder {
  width: calc(100% - 16px);
  display: inline-flex;
  align-items: center;
}
.searchFilter .filterCard-delete {
  width: 16px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.searchFilter .selectedFilters .selectedFilter {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px;
  height: 40px;
}
.searchFilter .selectedFilters .selectedFilter .filterName {
  display: flex;
  align-items: center;
}
.searchFilter .selectedFilters .selectedFilter .filterName .filterNameText {
  margin-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 300px;
}
.searchFilter .selectedFilters .selectedFilter .deleteFilter {
  width: 70px;
  margin-left: auto;
}
.searchFilter .mainFilterDropdown {
  max-height: 250px;
  overflow-y: auto;
}
.searchFilter .mainFilterDropdownOuter {
  padding: 3px;
  cursor: pointer;
}
.searchFilter .mainFilterDropdownOuter:hover {
  border-color: transparent;
}
.searchFilter .mainFilterDropdown .dropdownOption {
  display: flex;
  align-items: center;
  padding: 5px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.3s;
}
.searchFilter .mainFilterDropdown .dropdownOption:hover {
  background-color: var(--logpanel-dropdown-hover-bg-color, #d1e7fd);
  border: 1px solid var(--logpanel-dropdown-hover-border-color, #afd7ff);
}
.searchFilter .mainFilterDropdown .dropdownOptionText {
  color: var(--logpanel-dropdown-font-color, #363636);
  margin-left: 5px;
}
