.a10-filtertree-groupLabel {
  font-size: 14px;
}
.a10-filter-tree-label,
.a10-filter-tree-select,
.a10-filter-tree-input-btn {
  font-size: 12px;
}
.traceFilterList .ant-collapse-content.ant-collapse-content-active {
  padding-left: 18px !important;
}
.traceFilterList-type {
  padding: 4px;
  text-align: center;
}
