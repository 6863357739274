.wrapper {
  padding: 0 60px;
}
.container {
  position: relative;
  height: 150px;
  width: 100%;
  line-height: 1;
  font-size: 14px;
}
.circle {
  width: 120px;
  height: 120px;
  border: 1px solid #4a90e2;
  border-radius: 120px;
  text-align: center;
}
.leftCircle {
  position: absolute;
  left: 0;
  top: 20px;
}
.right-circle {
  position: absolute;
  right: 0;
  top: 20px;
}
.context {
  margin: 0 auto;
  padding-top: 25px;
}
.center {
  margin: 0 auto;
  position: absolute;
  left: 50%;
}
.center-fix {
  margin-left: -150px;
}
.top-inspect {
  width: 300px;
  height: 32px;
  border-radius: 4px;
  background-color: #ececec;
  border: solid 1px #d2d2d2;
  text-align: center;
  line-height: 32px;
  font-size: 16px;
}
.action {
  width: 300px;
  height: 66px;
  border-radius: 4px;
  border: solid 1px #4a90e2;
  background-color: #ffffff;
  padding: 2px;
}
.active {
  border: dashed 1px #4a90e2 !important;
  background-color: #d8eaff;
}
.disable {
  border: solid 1px #d2d2d2 !important;
  background-color: #ececec;
}
.action-in {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  border: solid 1px #4a90e2;
  line-height: 60px;
  text-align: center;
  font-size: 16px;
}
.action-out {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  border: solid 1px #4a90e2;
  line-height: 60px;
  text-align: center;
  float: right;
  margin-top: -60px;
  font-size: 16px;
}
.inner-line {
  border-top: 1px dashed #4a90e2;
  width: 170px;
  margin-left: 60px;
  margin-top: -30px;
}
.inner-icon {
  float: right;
  margin-right: 60px;
  margin-top: -38px;
}
.inner-word {
  text-align: center;
  padding-top: 8px;
}
.inner-action {
  text-align: center;
  padding-top: 8px;
  margin-top: -16px;
  min-height: 15px;
}
.arrowContent {
  width: 100%;
  height: 17px;
  background-color: #a1dbce23;
  margin-top: -9px;
  padding-top: 9px;
}
.left-arrow {
  width: 50%;
  position: absolute;
  left: 0;
  top: 84px;
  padding: 0 150px 0 120px;
}
.right-arrow {
  width: 50%;
  position: absolute;
  right: 0;
  top: 84px;
  padding: 0 120px 0 150px;
}
.arrow-line {
  width: 100%;
  border-top: 1px dashed #4a90e2;
}
.arrow-icon {
  float: right;
  margin-top: -9px;
}
.top-arrow {
  height: 20px;
}
.top-left-arrow {
  margin-left: 50px;
  height: 100%;
}
.top-right-arrow {
  float: right;
  margin-right: 50px;
  height: 100%;
  margin-top: -20px;
}
